import React from 'react'
import HeaderStyle1 from '../components/Header/HeaderStyle1'
import {FaFacebookF,FaPinterest,FaYoutube,FaGooglePlusG,FaStar,FaThumbsUp} from "react-icons/fa"
import FooterStyle1 from '../components/Footer/FooterStyle1'
import WechatImg from "../images/wechat1.jpg"
import JingXueImg from "../images/jingxue.jpg"
import JingXueTop10 from "../images/jingtop10.webp"
import QRImg from "../images/qrcode.jpg"
import QRImg12 from "../images/qrcode_12.jpg"
import {Helmet} from "react-helmet"

export default function Template({ data }) {
    const { markdownRemark } = data // data.markdownRemark holds your post data
    const { frontmatter, html } = markdownRemark

    return (
        <>
            <HeaderStyle1 />
            <div className="application">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{frontmatter.title + " | Jing Xue | 湾区置业专家 湾区Top华人房产经纪 | 熟悉南湾、东湾、三谷市场 | Remax Accord 排名第一 经纪人 | 近12个月成交量超1亿美金"}</title>
                    <meta name="description" content={frontmatter.description + " 一支秉持诚信、负责、专业的湾区TOP 1% 房产经纪人团队，南湾、东湾、三谷市场，手把手高效与您完成房产买卖服务"} />
                    <meta name="Keywords" content={frontmatter.keywords + ",湾区,华人房产经纪,南湾,东湾,三谷,地产经纪,pleasanton,fremont,cupertino,san jose,mountain view,dublin,palo alto,melo park,livermore,dublin,danville"}></meta>
                    <meta name="author" content="Jing Xue" />
                    <link rel="canonical" href="https://www.jingxuehomes.com" />
                    <script type="application/ld+json">{frontmatter.enhancement}</script>
                </Helmet>
            </div>
            <section className="pad80">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="single-blog-post">
                                <div className="blog-post">
                                    <h5>{frontmatter.date}</h5>
                                    <div
                                    className="blog-post-content"
                                    dangerouslySetInnerHTML={{ __html: html }}
                                    />
                                </div>
                                <div className={`testimonial-item text-center`}>
                                    <p>
                                    Always committed to providing a high level of service, Jing is known for timely and concise communication, always giving her best to exceed her clients' expectations. As a good listener, Jing will be responsive to all your needs, and guide new and experienced home buyers and sellers through every step of the process, and navigates complex transactions with ease and expertise to deliver results.
If you are looking for a true professional agent with high standards and a strong code of ethics, please contact Jing right now. I promise you will receive exceptional service and have a positive experience for your property transaction.
                                    </p>
                                    <img src={JingXueImg} alt="" className="img-circle" />
                                    <img src={QRImg} alt="" className="img-circle" />
                                    <div className="name">Jing Xue</div>
                                    <div className="designation">Bay Area Top Realtor</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="widget widget-tag">
                                <div className="widget-title">
                                    <h3>Tag Cloud</h3>
                                </div>
                                <ul className="tags-list">
                                    <li><a href="/blogs">买房攻略</a></li>
                                    <li><a href="/blogs">湾区买房</a></li>
                                    <li><a href="/blogs">Single Family</a></li>
                                </ul>
                            </div>
                            <div className="widget widget-text">
                                <div className="widget-title">
                                    <h3>Top 1% Bay Area Agent</h3>
                                </div>
                                <p>If you are looking for a true professional agent with high standards and a strong code of ethics, please contact Jing right now. I promise you will receive exceptional service and have a positive experience for your property transaction.</p>
                                <p>过去12个月已经协助72个家庭完成房屋买卖服务，销售额余九千万美金。南湾东湾三谷地区TOP华人经纪。</p>
                                <p>Remax全球Top Producer，2021年1月至6月连续获Top Agent!</p>
                                <p>多年500强公司丰富的市场营销背景，具备高效的谈判能力、观察能力和反应能力对市场有精确了解，为客户节省时间和精力，在最短时间完成买卖交易。</p>
                                <p>拥有强大的经纪人网络，可在off market解决您的买卖需求。</p>
                                <p>把客户买卖当做自己的买卖的房产经纪人，每一个环节与客户耐心沟通，专业解答。7天24小时都可以联系到我们！</p>
                                <p>专业的室内装修、园林维护、设计师、摄影师、清洁、搬家服务团队，为您提供买卖房产中所需的一条龙服务，使得房屋买卖轻松高效！</p>
                                <div id="qrcode" style={{ height: "500px" }}><img src={JingXueTop10} alt="" /></div>
                            </div>
                            <div className="widget widget-text">
                                <div className="widget-title">
                                    <h3>Contact Right</h3>
                                </div>
                                <p>You can contact or visit us during working time.</p>
                                <p>Tel: +1-(650)-542-1628</p>
                                <p>Email: xuesimone@gmail.com</p>
                                <p>Working Hours: 8:00 a.m - 18:00 p.m</p>
                            </div>
                            <div className="widget widget-text">
                                <div className="widget-title">
                                    <h3>Wechat</h3>
                                </div>
                                <div id="qrcode" style={{ height: "400px" }}>
                                    <img src={WechatImg} alt="" />
                                    <img src={QRImg12} alt="" className="img-circle" />
                                </div>
                            </div>
                            <div className="widget widget-social">
                                <ul>
                                    <li><a href="https://www.facebook.com/jing.xue.75491"><span><FaFacebookF/></span></a></li>
                                    <li><a href="https://www.google.com/search?q=jing+xue+realtor&#lrd=0x808feb5994a4c681:0xdb249dd99ecaacc9,1,,,"><span><FaGooglePlusG/></span></a></li>
                                    <li><a href="https://www.zillow.com/profile/Jing-Xue/"><span><FaStar/></span></a></li>
                                    <li><a href="https://www.pinterest.com/xuesimone/"><span><FaPinterest/></span></a></li>
                                    <li><a href="https://www.dealmoon.com/local-store/45159"><span><FaThumbsUp/></span></a></li>
                                    <li><a href="https://www.youtube.com/channel/UCTNq0iPCZDG6I9pxngpK7EQ"><span><FaYoutube/></span></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>

            <FooterStyle1/>
        </>
    )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        description
        keywords
        enhancement
      }
    }
  }
`